import { IOrderStore } from './order-store.model';

export class SetOrder {
  static type = '[ORDER] set order';
  constructor(
    public payload: IOrderStore
  ) { }
}

export class ResetOrder {
  static type = '[ORDER] reset order';
  constructor() { }
}
