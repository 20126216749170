import { IVenueStore } from './venue-store.model';

export class SetVenue {
  static readonly type = '[VENUE] set venue';
  constructor(
    public payload: IVenueStore
  ) { }
}

export class ResetVenue {
  static readonly type = '[VENUE] reset venue';
  constructor() { }
}
