import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ResetTable, SetTable } from './table-store.actions';
import { ITableStore } from './table-store.model';

@State<ITableStore>({
  name: 'table',
  defaults: null
})
@Injectable()
export class TableStore {

  @Action(SetTable)
  setTable(
    { setState }: StateContext<ITableStore>,
    { payload }: SetTable
  ): void {
    setState(payload);
  }

  @Action(ResetTable)
  resetTable(
    { setState }: StateContext<ITableStore>
  ): void {
    setState(null);
  }
}
