import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ResetVenue, SetVenue } from './venue-store.actions';
import { IVenueStore } from './venue-store.model';

@State<IVenueStore>({
  name: 'venue',
  defaults: null
})
@Injectable()
export class VenueStore {

  @Action(SetVenue)
  setVenue(
    { setState }: StateContext<IVenueStore>,
    { payload }: SetVenue
  ): void {
    setState(payload);
  }

  @Action(ResetVenue)
  resetVenue(
    { setState }: StateContext<IVenueStore>
  ): void {
    setState(null);
  }
}
