
export class SetAddOn {
  static readonly type = '[Add ON] set add Ons';
  constructor(
    public payload: any
  ) { }
}

export class ResetAddOn {
  static readonly type = '[ADD ON] reset add Ons';
  constructor() { }
}
