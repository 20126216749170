import { ITableStore } from './table-store.model';

export class SetTable {
  static type = '[TABLE] set table';
  constructor(
    public payload: ITableStore
  ) { }
}

export class ResetTable {
  static type = '[TABLE] reset table';
  constructor() { }
}
