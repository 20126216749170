import { IDriverDetailsStore } from './user-store.model';

export class SetDriverDetails {
  static readonly type = '[DRIVER] set driver details';
  constructor(
    public payload: IDriverDetailsStore
  ) { }
}

export class ResetDriverDetails {
  static readonly type = '[DRIVER] reset driver details';
  constructor() { }
}
