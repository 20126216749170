export class SetDeliveryAddress {
  static readonly type = '[DELIVERY ADDRESS] set devery address';
  constructor(
    public payload: any
  ) { }
}

export class ResetDeliveryAddress {
  static readonly type = '[DELIVERY ADDRESS] reset devery address';
  constructor() { }
}
