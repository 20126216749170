import { TableStore } from './table/table-store.state';
import { AddOnStore } from './addOn/add-on-store.state';
import { DeliveryAddressStore } from './deliveryAddress/delivery-address-store.state';
import { VenueStore } from './venue/venue-store.state';
import { OrderStore } from './order/oder-store.state';
import { CityStore } from './city/city-store.state';
import { UserStore } from './user/user-store.state';
import { DriverStore } from './driver/user-store.state';

export const store = [
  UserStore,
  DriverStore,
  CityStore,
  OrderStore,
  VenueStore,
  DeliveryAddressStore,
  AddOnStore,
  TableStore
  // OrderStore
];
