import { SharedModule } from './../../directives/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoodInfoComponent } from './food-info.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    SharedModule
  ],
  exports:[
    FoodInfoComponent
  ],
  declarations: [FoodInfoComponent]
})
export class FoodInfoModule { }
