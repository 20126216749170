import { SetAddOn, ResetAddOn } from './add-on-store.actions';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

@State<any>({
  name: 'addOns',
  defaults: null
})
@Injectable()
export class AddOnStore {

  @Action(SetAddOn)
  setVenue(
    { setState }: StateContext<any>,
    { payload }: SetAddOn
  ): void {
    setState(payload);
  }

  @Action(ResetAddOn)
  resetVenue(
    { setState }: StateContext<any>
  ): void {
    setState(null);
  }
}
