import { SetOrder } from './../../core/store/order/order-store.actions';
import { Store } from '@ngxs/store';
import { UtilService } from './../../services/util.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-food-info',
  templateUrl: './food-info.component.html',
  styleUrls: ['./food-info.component.css']
})
export class FoodInfoComponent implements OnInit {
  @Input() food: any;
  @Input() oldOrder: any;
  @Input() index: any;
  @Input() restName: any;
  notes = ""
  addOns;

  allOrders = { order: [], food: [], type: '' };
  order: any = {
    total: 0,
    quantity: 0,
    food: {
      name: '',
      price: 0
    },
    config: {
      extras: [],
      sides: [],
      options: [],
      notes: ''
    }
  }

  sidesAmount = 1;
  dummy: any;
  constructor(
    private modalCtrl: ModalController,
    private util: UtilService,
    private store: Store
  ) { }

  ngOnInit() {
    this.allOrders = { ...this.store.selectSnapshot((s) => s.order) };
    this.addOns = this.store.selectSnapshot((s) => s.addOns);
    if (!this.allOrders) {
      this.allOrders = { order: [], food: [], type: '' };
    }
    if (this.index) {
      this.order = { ...this.allOrders.order[this.index] }
      this.food = { ...this.allOrders.food[this.index] }
      this.dummy = this.food;
      this.notes = this.order.config.notes
    }
    else if (this.food) {
      this.order.food.name = this.food.name
      this.order.food.price = this.food.price
      this.dummy = this.food;
      this.add()
    }
    this.calculate();
  }

  add() {
    this.order.quantity++;
    this.calculate();
  }

  addNotes(val) {
    let tempOrder = {
      ...this.order,
      config: {
        ...this.order.config,
        notes: val.detail.value
      }
    }
    this.order = tempOrder
  }

  inArray(type, extra) {
    let res = false;
    if (this.order.config[type]) {
      this.order.config[type].forEach(element => {
        if (element.name == extra.name) {
          if (type == 'extras') {
            if (extra.price == element.price) {
              res = true;
            }
          }
          else {
            res = true;
          }
        }
      });
    }
    else {
      res = false
    }
    return res
  }

  removeItem(item) {
    let removed = false;
    let temp = [];
    this.order.config[item].forEach(element => {
      if (removed) {
        temp.push(element)
      }
      removed = true;
    });
    let tempOrder = {
      ...this.order,
      config: {
        ...this.order.config,
        [item]: temp
      }
    }
    // this.order.config[item] = temp
    this.order = tempOrder
  }

  toggleArray(type, extra) {
    if (!this.inArray(type, extra)) {
      let temp = [];
      temp.push(extra)
      let tempOrder = {
        ...this.order,
        config: {
          ...this.order.config,
          [type]: temp
        }
      }
      this.order = tempOrder
      // this.order.config[type].push(extra);
    } else {
      let temp = [];
      this.order.config[type].forEach(element => {
        if (element.name != extra.name) {
          if (type == 'extras') {
            if (extra.price != element.price) {
              temp.push(element)
            }
          } else {
            temp.push(element)
          }
        }
      });
      let tempOrder = {
        ...this.order,
        config: {
          ...this.order.config,
          [type]: temp
        }
      }
      this.order = tempOrder
    }
    if (type == 'sides' && this.order.config[type].length > this.sidesAmount) {
      this.removeItem(type);
    }
    else if (type == 'options' && this.order.config[type].length > 1) {
      this.removeItem(type);
    }
    this.calculate();
  }

  checkAllFilledOut() {
    let allGood = true;
    if (this.order.quantity > 0) {
      if (this.food.sides.length > 0) {
        if (this.order.config.sides.length < this.sidesAmount) {
          allGood = false;
        }
      }
      if (this.food.options.length > 0) {
        if (this.order.config.options.length == 0) {
          allGood = false;
        }
      }
    }
    return allGood;
  }


  cancel() {
    this.modalCtrl.dismiss({
    });
  }

  remove() {
    this.order.quantity--;
    this.calculate();
  }

  addToOrders() {
    let dummy = []
    let dummyFood = []
    let currentIndex = 0;
    let added = false
    if (!this.allOrders.order || this.allOrders.order.length == 0) {
      dummy.push(this.order)
      dummyFood.push(this.food)
      added = true
    }
    else {

      this.allOrders.order.forEach((element) => {
        if (this.index) {
          if (this.index == currentIndex) {
            dummy.push(this.order)
            dummyFood.push(this.allOrders.food[currentIndex])
          }
          else {
            dummy.push(element)
            dummyFood.push(this.allOrders.food[currentIndex])
          }
        }
        else {
          dummy.push(element)
          dummyFood.push(this.allOrders.food[currentIndex])
        }
        currentIndex++;
      })
    }

    if (!this.index && !added) {
      dummy.push(this.order)
      dummyFood.push(this.food)
    }

    let tempOrder = { order: [], food: [], type: this.allOrders.type };
    tempOrder.order = dummy;
    tempOrder.food = dummyFood;
    this.allOrders = tempOrder;
  }

  async addToOrder() {
    if (this.checkAllFilledOut()) {
      this.addToOrders()
      this.store.dispatch(new SetOrder(this.allOrders));
      this.modalCtrl.dismiss({
      });
    }
    else {
      this.util.showToast('Select all Sides and Options <ion-icon name="color-wand-outline"></ion-icon>', 'secondary', 'bottom');
    }

  }

  totalPrice;

  async calculate() {
    let total = 0;
    if (this.order.quantity > 0) {
      total = total + (parseFloat(this.food.price) * parseInt(this.order.quantity));
      this.order.config.extras.forEach(element => {
        element.value.forEach(el => {
          total = total + (parseFloat(el.price)) * this.order.quantity;

        });
      });
    }
    this.order = {
      ...this.order, total: total.toFixed(2)
    }
  }

  getAddOnName(extra) {
    let name = 'Error';
    this.addOns.forEach(element => {
      if (element.uid == extra) {
        name = element.name
        return;
      }
    });
    return name;
  }

  selectItem(type, side, item, extra = null) {
    let found = false;
    let tempConfig = [];
    this.order.config[type].forEach(element => {
      if (element.uid == side && extra == element.extraId) {
      }
      else {
        tempConfig.push(element)
      }
    });
    if (!found) {
      tempConfig.push({
        uid: side,
        name: this.getAddOnName(side),
        value: item,
        extraId: extra
      })
    }
    // this.order.config[type] = tempConfig;
    this.order = {
      ...this.order,
      config: {
        ...this.order.config,
        [type]: tempConfig
      }
    }
    this.calculate()
  }

  itemSelected(type, side, item, extra = null) {
    let tempFlag = false;
    if (this.order.config[type] != undefined) {
      if (this.order.config[type] != undefined) {
        this.order.config[type].forEach(element => {
          if (
            element.uid == side &&
            element.value.name == item.name &&
            element.value.price == item.price &&
            element.extraId == extra) {
            tempFlag = true;
          }
        });
      }
    }
    return tempFlag
  }

  selectExtra(type, side, item) {
    let temp = []
    let tempConfig = []
    if (this.extraSelected(type, side, item)) {
      let sideFound = false;
      this.order.config[type].forEach(element => {
        if (element.uid == side) {
          element.value.forEach(el => {
            if (el.name == item.name) {
              sideFound = true;
            }
            else {
              temp.push(el)
            }
          });
        }
        else {
          tempConfig.push(element)
        }
      });
    }
    else {
      let sideFOund = false;
      this.order.config[type].forEach(element => {
        if (element.uid == side) {
          element.value.forEach(el => {
            temp.push(el)
          });
        }
        else {
          tempConfig.push(element)
        }
      });
      temp.push(item)
      tempConfig.push({
        uid: side,
        name: this.getAddOnName(side),
        value: temp
      });
    }
    this.order = {
      ...this.order,
      config: {
        ...this.order.config,
        [type]: tempConfig
      }
    }
    this.calculate()
  }

  extraSelected(type, side, item) {
    let isSelected = false;
    if (this.order.config[type] != undefined && this.order.config[type] != null) {
      this.order.config[type].forEach(element => {
        if (element.uid == side) {
          element.value.forEach(el => {
            if (el.name == item.name && el.price == item.price) {
              isSelected = true;
            }
          });
        }
      });
    }
    return isSelected;
  }



  getAddOnSelection(addOn) {
    let addonsArray = []
    this.addOns.forEach(element => {
      if (element.uid == addOn) {
        addonsArray = element.addOns
        return;
      }
    });
    return addonsArray;
  }

}
