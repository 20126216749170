import { ICityDetailsStore } from './city-store.model';

export class SetCityDetails {
  static readonly type = '[CITY] set city details';
  constructor(
    public payload: ICityDetailsStore
  ) { }
}

export class ResetCityDetails {
  static readonly type = '[CITY] reset city details';
  constructor() { }
}
