import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ResetOrder, SetOrder } from './order-store.actions';
import { IOrderStore } from './order-store.model';

@State<IOrderStore>({
  name: 'order',
  defaults: null
})
@Injectable()
export class OrderStore {

  @Action(SetOrder)
  setOrder(
    { setState }: StateContext<IOrderStore>,
    { payload }: SetOrder
  ): void {
    setState(payload);
  }

  @Action(ResetOrder)
  resetOrder(
    { setState }: StateContext<IOrderStore>
  ): void {
    setState(null);
  }
}
