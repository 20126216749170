import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IDriverDetailsStore } from './user-store.model';
import { SetDriverDetails, ResetDriverDetails } from './user-store.actions';

@State<IDriverDetailsStore>({
  name: 'driver',
  defaults: null
})
@Injectable()
export class DriverStore {

  @Action(SetDriverDetails)
  setUser(
    { setState }: StateContext<IDriverDetailsStore>,
    { payload }: SetDriverDetails
  ): void {
    setState(payload);
  }

  @Action(ResetDriverDetails)
  resetUser(
    { setState }: StateContext<IDriverDetailsStore>
  ): void {
    setState(null);
  }
}
