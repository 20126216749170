import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ResetDeliveryAddress, SetDeliveryAddress } from './delivery-address-store.actions';

@State<any>({
  name: 'deliveryAddress',
  defaults: null
})
@Injectable()
export class DeliveryAddressStore {

  @Action(SetDeliveryAddress)
  setVenue(
    { setState }: StateContext<any>,
    { payload }: SetDeliveryAddress
  ): void {
    setState(payload);
  }

  @Action(ResetDeliveryAddress)
  resetVenue(
    { setState }: StateContext<any>
  ): void {
    setState(null);
  }
}
