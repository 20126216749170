<ion-header  color='primary'>
  <ion-toolbar color='primary'>
    <ion-icon name="arrow-back-outline" style='font-size:x-large' (click)='cancel()'></ion-icon>
  </ion-toolbar>
  <ion-toolbar style='padding:20px'>
    <ion-card-title style='text-align: start;' color='primary'>{{food.name}}</ion-card-title>
    <ion-card-subtitle>{{food.description}}</ion-card-subtitle>
  </ion-toolbar>
</ion-header>
<ion-content scrollY="true">
  <div style='height:100%;background-color: lightgray;overflow-y: scroll;'>
      <ng-container *ngIf="food.options.length > 0">
        <ion-toolbar color='tertiary'>
          <ion-title>Meal Options</ion-title>
        </ion-toolbar>
        <ion-list lines="none"  style='background-color: lightgray;margin: 5px;'>
          <ng-container *ngFor="let side of food.options; let idx = index">
            <ion-label>
             <b> {{getAddOnName(side)}}</b>
            </ion-label>
            <ion-list style='background-color: lightgray;'>
              <ion-item *ngFor="let item of getAddOnSelection(side)" (click)="selectItem('options',side,item,idx)" style='border-radius: 5px; margin: 5px;'>
                {{item.name}}
                <ion-icon slot='end' name='checkmark' [color]="itemSelected('options',side,item,idx) ? 'secondary' : 'medium'">
                </ion-icon>
              </ion-item>
            </ion-list>
          </ng-container>
        </ion-list>
      </ng-container>

      <ng-container *ngIf="food.sides.length > 0">
        <ion-toolbar color='tertiary'>
          <ion-title>Sides</ion-title>
        </ion-toolbar>
        <ion-list lines="none"  style='background-color: lightgray;margin: 5px;'>
          <ng-container *ngFor="let side of food.sides; let idx = index">
            <ion-label>
             <b> {{getAddOnName(side)}}</b>
            </ion-label>
            <ion-list style='background-color: lightgray;'>
              <ion-item *ngFor="let item of getAddOnSelection(side)" (click)="selectItem('sides',side,item,idx) " style='border-radius: 5px; margin: 5px;'>
                {{item.name}}
                <ion-icon slot='end' name='checkmark' [color]="itemSelected('sides',side,item,idx) ? 'secondary' : 'medium'">
                </ion-icon>
              </ion-item>
            </ion-list>
          </ng-container>
        </ion-list>
      </ng-container>

      <ng-container *ngIf="food.extras.length > 0">
        <ion-toolbar color='tertiary'>
          <ion-title>Extras</ion-title>
        </ion-toolbar>
        <ion-list lines="none"  style='background-color: lightgray;margin: 5px;'>
          <ng-container *ngFor="let side of food.extras">
            <ion-label style=''>
             <b> {{getAddOnName(side)}}</b>
            </ion-label>
            <ion-list style='background-color: lightgray;'>
              <ion-item *ngFor="let item of getAddOnSelection(side)" (click)="selectExtra('extras',side,item)" style='border-radius: 5px; margin: 5px;'>
                <ion-label>
                  {{item.name}}
                </ion-label>
                 <ion-label slot='end'>
                  R {{item.price}}
                 </ion-label> 
                <ion-icon slot='end' name='checkmark' [color]="extraSelected('extras',side,item) ? 'secondary' : 'medium'">
                </ion-icon>
              </ion-item>
            </ion-list>
          </ng-container>
        </ion-list>
      </ng-container>
      <ion-item lines='none' style='background-color: lightgray;border-radius: 5px; margin: 7px;'>
        <ion-textarea (ionChange)='addNotes($event)' [(ngModel)]="notes" placeholder='Extra Instructions' style='border-radius: 5px; margin: 5px;'></ion-textarea>
      </ion-item>
    </div>
</ion-content>
<ion-footer color='primary' >
  <ion-list style='background-color: lightgray;'>
    <ion-item style='background-color: lightgray;'>
      <ion-fab-button slot='start' color="primary" (click)="remove()" [disabled]='this.order.quantity == 0'>
        <ion-icon name="remove-outline"></ion-icon>
      </ion-fab-button>
      <ion-label style='font-size:x-large;text-align: center;'>{{order.quantity}}</ion-label>
      <ion-fab-button slot='end' color="primary" (click)="add()">
        <ion-icon name="add-outline"></ion-icon>
      </ion-fab-button>
    </ion-item>
  </ion-list>
  <ion-item lines='none' color='primary' style='width:100%' (click)='addToOrder()'>
    <ion-label>
      Item Total :
    </ion-label>
    <ion-note slot="end">R {{order.total}}</ion-note>
  </ion-item>
  <ion-item lines='none' color='primary' style='width:100%;text-align:center' (click)='addToOrder()'>
    <ion-label>
      Update Order
    </ion-label>
  </ion-item>
  <!-- <ion-button expand="full" color='primary' (click)='addToOrder()'>Update Order</ion-button> -->
</ion-footer>