import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ICityDetailsStore } from './city-store.model';
import { SetCityDetails, ResetCityDetails } from './city-store.actions';

@State<ICityDetailsStore>({
  name: 'city',
  defaults: null
})
@Injectable()
export class CityStore {

  @Action(SetCityDetails)
  setUser(
    { setState }: StateContext<ICityDetailsStore>,
    { payload }: SetCityDetails
  ): void {
    setState(payload);
  }

  @Action(ResetCityDetails)
  resetUser(
    { setState }: StateContext<ICityDetailsStore>
  ): void {
    setState(null);
  }
}
